import { Anchor, Input, Table, tableFilters, TablePaginator,Button} from "@itwin/itwinui-react";
import { useMemo, useCallback, useState, useEffect } from "react";
import { actions, ActionType, Row, TableInstance, TableState } from 'react-table';
import { PipeData } from "./PipeData";
import "../App.scss";
import { clearEmphasisAndColor, populateMap, zoomToElementsAndEmphasize,onlyEmphasize,populateAdjcentPipes,removegraphicMarkersfromData } from "../Utils/Utils";
import { HiliteElementsApi } from "../HiliteElementsApi";
import { PipeRunType, Spools } from "../types";
import { SvgVisibilityHide} from '@itwin/itwinui-icons-react';
import { SpoolData } from "../SpoolBox/SpoolData";
import { PipeSpoolsDecorator } from "../PipeSpoolsDecorator";
import { ControlValveDecorator } from "../Utils/ControlValveDecorator";
import { InstrumentDecorator } from "../Utils/InstrumentDecorator";
import { SmartEquipmentData } from "../SmartEquipment/SmartEquipmentData";
import { EquipmentDecorator } from "../Utils/EquipmentDecorator";
//import { Console } from "console";
//import { SpoolBoxDecorator } from "../Utils/SpoolBoxDecorator";
//import { SpoolBoxDecorator } from "../Utils/SpoolBoxDecorator";





export const PipeList = () => {
    const [globalFilter, setGlobalFilter] = useState('');
    const [pipeData, setPipeData] = useState<{pipeRun: string, links: {iSOLink: string, pIDLink: string}}[]>([]);
    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
    const [drawing, setDrawing] = useState<string>(); // stores the respective DWGNO of the row in pipe list
    const enableClearEmphasis = process.env.IMJS_ENABLE_CLEAR_EMPHASIS ? JSON.parse(process.env.IMJS_ENABLE_CLEAR_EMPHASIS.toLowerCase()) :false
    //const [decoratorState, setDecoratorState] = useState<GeometryDecorator>();

    useEffect(() => {
        const zoomElems = async () => {            
            if (drawing !=="" && PipeData.pipesMap.has(drawing)) {                
                const value = PipeData.pipesMap.get(drawing); 
                const adjcentpipes = await populateAdjcentPipes(value.pipLine,drawing);
                console.log(adjcentpipes);
                const spooldata = await SpoolData.getSpoolData(drawing);
               
                for await (const pipes of adjcentpipes) {
                    
                    if (pipes[1].hiliteElems.length === 0) {
                       const hiliteElementsApi = new HiliteElementsApi(PipeData.iModel);
                       const hiliteElemSet = await hiliteElementsApi.getHiliteSetElementIds(PipeData.iModel, pipes[1].keySet);
                  
                      // Update the object in the map
                      const originalObject = PipeData.pipesMap.get(pipes[0]);
                      if (originalObject) {
                        const updatedObject = {
                          ...originalObject,
                          hiliteElems: hiliteElemSet
                        };
                        PipeData.pipesMap.set(pipes[0], updatedObject);
                      }
                    }
                }
                //ORGINAL CODE
                // if (value.hiliteElems.length === 0) {
                //     const hiliteElementsApi = new HiliteElementsApi(PipeData.iModel);
                //     const hiliteElemSet = await hiliteElementsApi.getHiliteSetElementIds(PipeData.iModel, value.keySet);
                //     const originalObject = value;
                //     const updatedObject = {
                //         ...originalObject,
                //         hiliteElems: hiliteElemSet
                //     };
                //     PipeData.pipesMap.set(drawing, updatedObject);
                    
                // }
                
                const filterData = [];
                for (const [key, value] of adjcentpipes) {
                    if (key !== drawing) {
                      filterData.push(...PipeData.pipesMap.get(key).hiliteElems);
                    }
                }

                 let idsForBoudingBoxes = PipeData.pipes.filter(pipeRun => pipeRun.pipeRun === drawing)[0];
                 let elements =[];
                 elements = JSON.parse(idsForBoudingBoxes.elements as any);

                 let ids = elements.filter((element : any) =>{
                     if(element.spool !== "null"){
                        return true;
                     }
                 }).map((item:any) => item.id)

                removegraphicMarkersfromData();
                PipeSpoolsDecorator.displaySpoolGroupMarkers(spooldata);
                localStorage.setItem("Spools", JSON.stringify(spooldata));
                ControlValveDecorator.getControlValve(drawing);
                InstrumentDecorator.getInstrument(drawing);
                EquipmentDecorator.getEquipmentList(drawing);
                await onlyEmphasize(filterData, PipeData.lastAdjacentEmphasizedPipes);
                await zoomToElementsAndEmphasize(PipeData.pipesMap.get(drawing).hiliteElems, PipeData.lastEmphasizedPipes,PipeData.pipesMap.get(drawing).pipLine, PipeData.lastSelectedPipeLine);
                PipeData.lastEmphasizedPipes = PipeData.pipesMap.get(drawing).hiliteElems;
                PipeData.lastAdjacentEmphasizedPipes =  filterData;  
                PipeData.lastSelectedPipeLine = PipeData.pipesMap.get(drawing).pipLine;                 
            }
        }
        zoomElems();
    }, [drawing]);

    useEffect(() => {
        const setRowsSelected = (e: any) => {
            var val = e.detail
            var index = pipeData.findIndex(function (item, i) {
                return item.pipeRun === val
            });
            setSelectedRows((rowIds) => {
                const selectedRowIds = { ...rowIds };
                for (const key in selectedRowIds) {
                    selectedRowIds[key] = false;
                }
                selectedRowIds[index] = true;
                return selectedRowIds;
            });
        }
        document.addEventListener("QRCodeScanned", (e) => setRowsSelected(e));
        return () => {
            document.removeEventListener("QRCodeScanned", (e) => setRowsSelected(e));
        };
    });

    useEffect(() => {
        const getPipes = async () => {
            let pipes = await PipeData.fetchPipes();
            let equipment = await SmartEquipmentData.fetchEquipment();
            PipeData.pipes = pipes;
            SmartEquipmentData.equipments = equipment;        
            const pipesUniqueByDwgno: PipeRunType[] = pipes.filter((elem: any, index: any) => PipeData.pipes.findIndex((obj: any) => obj.pipeRun === elem.pipeRun) === index);
            let tableData: {pipeRun: string, links: {iSOLink: string, pIDLink: string}}[] = [];
            pipesUniqueByDwgno.forEach((p) => {
                let rowObj = {
                    pipeRun: p.pipeRun,
                    links: {
                        iSOLink: p.iSOLink,
                        pIDLink: p.pIDLink
                    }
                };
                tableData.push(rowObj);
            });
            setPipeData(tableData);
            await populateMap(PipeData.pipes);                          
        }
        getPipes();
    }, []);

    const tableStateReducer = (
        newState: TableState,
        action: ActionType,
        previousState: TableState,
        instance?: TableInstance,
    ): TableState => {
        switch (action.type) {
            case actions.toggleRowSelected: {
                const newSelectedRows = {
                    ...selectedRows,
                };
                if (action.value) {
                    newSelectedRows[action.id] = true;
                } else {
                    delete newSelectedRows[action.id];
                }
                setSelectedRows(newSelectedRows);
                newState.selectedRowIds = newSelectedRows;
                break;
            }
            case actions.toggleAllRowsSelected: {
                if (!instance?.rowsById) {
                    break;
                }
                const newSelectedRows = {} as Record<string, boolean>;
                if (action.value) {
                    Object.keys(instance.rowsById).forEach(
                        (id) => (newSelectedRows[id] = true),
                    );
                }
                setSelectedRows(newSelectedRows);
                newState.selectedRowIds = newSelectedRows;
                break;
            }
            default:
                break;
        }
        return newState;
    };

    const controlledState = useCallback(
        (state) => {
            return {
                ...state,
                selectedRowIds: { ...selectedRows },
            };
        },
        [selectedRows],
    );

    const columns = useMemo(
        () => [
            {
                Header: "Table",
                columns: [
                    {
                        id: "pipeRun",
                        Header: "Pipe Run",
                        accessor: "pipeRun",
                        Filter: tableFilters.TextFilter(),
                    },                    
                    {
                        id: "links",
                        Header: "Links",
                        accessor: "links",
                        Filter: tableFilters.TextFilter(),
                        Cell: (data: any) => {
                            return <div style={{ display: "flex" }}>
                                {data.row.original.links.iSOLink ? <Anchor style={{ float: "left" }} href={data.row.original.links.iSOLink} target="_blank">ISO</Anchor> : ""}
                                {data.row.original.links.pIDLink ? <Anchor style={{ float: "right", marginLeft: "5px" }} href={data.row.original.links.pIDLink} target="_blank">PID</Anchor> : ""}
                            </div>
                        },
                    },
                ],
            },
        ],
        []);

    const pageSizeList = useMemo(() => [10, 25, 50], []);
    const paginator = useCallback(
        (props) => (
           
            <TablePaginator style={{ border: 'none',display: 'flex', justifyContent: 'space-between' }} {...props} pageSizeList={pageSizeList} />
          
        ),
        [pageSizeList],
    );

    const onRowClick = useCallback(
        (event: React.MouseEvent, row: Row) => {
            setSelectedRows((rowIds) => {
                const selectedRowIds = { ...rowIds };
                for (const key in selectedRowIds) {
                    selectedRowIds[key] = false;
                }
                selectedRowIds[row.id] = true;
                return selectedRowIds;
            });
            setDrawing(row.values.pipeRun);
        }, [],
    );

    const handleClearEmphasis =()=>{
        setDrawing("");
        setSelectedRows((rowIds) => {
            const selectedRowIds = { ...rowIds };
            for (const key in selectedRowIds) {
                selectedRowIds[key] = false;
            }            
            return selectedRowIds;
        });
        clearEmphasisAndColor();
    }

    return (
        <>
            <div style={{ margin: '10px' }}>
                <h3 style={{ textAlign: "center" }}>Pipe Runs</h3>
                <div style={{ display: 'flex', margin: '8px 0', position: 'sticky', top: 0, zIndex: 1, background: '#fff' }}>
                    <Input
                        style={{ border: '1px solid lightgray' }}
                        placeholder='Search Pipe...'
                        value={globalFilter}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setGlobalFilter(e.target.value)
                        }
                    />
                    {enableClearEmphasis? <Button startIcon={<SvgVisibilityHide/>} disabled={!enableClearEmphasis} title="Clear emphasize"  style={{ border: '1px solid lightgray' }} onClick={handleClearEmphasis}></Button> : null}
                    
                </div>
                <Table style={{ border: '1px solid lightgray' }}
                    columns={columns}
                    data={pipeData}
                    styleType="zebra-rows"
                    emptyTableContent='No data.'
                    density="condensed"
                    paginatorRenderer={paginator}
                    onRowClick={onRowClick}
                    globalFilterValue={globalFilter}
                    selectionMode='single'
                    isSelectable={true}
                    useControlledState={controlledState}
                    stateReducer={tableStateReducer}
                    isSortable
                />
            </div>
        </>
    );
}
