import { QueryRowFormat } from "@itwin/core-common";
import { DecorateContext, Decorator, ScreenViewport } from "@itwin/core-frontend";
import { UiFramework } from "@itwin/appui-react";
import { PipeSpoolsMarker } from "../Markers/PipeSpoolsMarker";

export class InstrumentDecorator implements Decorator {
   private static _markers: PipeSpoolsMarker[] = [];
   private static enabled: boolean = true;
   private static  vp : ScreenViewport;
    constructor(_vp: ScreenViewport) {
      InstrumentDecorator.vp = _vp;
    }

    public static enable(){
      InstrumentDecorator.enabled = true;
      this.vp.invalidateDecorations();
      
    }

    public static disable(){
      InstrumentDecorator.enabled = false;
      this.vp.invalidateDecorations();
    }

    public static SetMarkers(){
      InstrumentDecorator._markers = [];
      var data = JSON.parse(localStorage.getItem('Instrument') || '{}');
      if(data.length > 0){
        data.forEach((element: any) => {
          const marker = new PipeSpoolsMarker(
              element.origin,
              { x: 20, y: 20 },
              element.name
          );
          InstrumentDecorator._markers.push(marker);
        });
      }
     
    }
    public static async getInstrument(list?: string) {
      if (!list) return;
      const values = [];
      const query = `SELECT snwd.Name,ge3.ECInstanceId,ge3.Origin FROM Bis.GeometricElement3d ge3 INNER join (SELECT nwd.ECInstanceId,smart3D.Name FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO = '${list}' and smart3d.ClassName_RN = 'CPRtePipeInstrumentOcc') snwd on snwd.ECInstanceId = ge3.ECInstanceId where ge3.Origin is NOT Null`;
      const result = UiFramework.getIModelConnection()!.createQueryReader(query, undefined, {
        rowFormat: QueryRowFormat.UseJsPropertyNames,
      });
      //const values = (await result.toArray()).map((element: any) => element);
      values.push(...(await result.toArray()));
      localStorage.setItem("Instrument", JSON.stringify(values));
      InstrumentDecorator.SetMarkers();
    }

    public decorate(context: DecorateContext): void {
      if (!InstrumentDecorator.enabled) {
        return;
       }
      // Adding the markers as decorations
      InstrumentDecorator.SetMarkers();
      InstrumentDecorator._markers.forEach(marker => {
          marker.addDecoration(context);
      });
  }
}

