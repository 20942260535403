import * as React from 'react';
import { ToggleSwitch, InputGroup } from '@itwin/itwinui-react';
import { StagePanelLocation,
  StagePanelSection, Widget , UiItemsProvider, WidgetState } from '@itwin/appui-react';
import { EquipmentDecorator } from '../Utils/EquipmentDecorator';
import { useEffect } from 'react';
import { PipeSpoolsDecorator } from '../PipeSpoolsDecorator';
import { ControlValveDecorator } from '../Utils/ControlValveDecorator';
import { InstrumentDecorator } from '../Utils/InstrumentDecorator';




  
export const Toggle = () =>{
  const [showEquipmentDecorator, setShowEquipmentDecorator] = React.useState<boolean>(true);
  const [showControlValveDecorator, setShowControlValveDecorator] = React.useState<boolean>(true);
  const [showPipeSpoolsDecorator, setShowPipeSpoolsDecorator] = React.useState<boolean>(true);
  const [showInstrumentDecorator, setShowInstrumentDecorator] = React.useState<boolean>(true);

  useEffect(() => {
    const showData =  () => {
       if(showEquipmentDecorator){
          EquipmentDecorator.enable();
       }else{
        EquipmentDecorator.disable();
       }
    };
    showData();
  }, [showEquipmentDecorator]);

  useEffect(() => {
    const showControlvalveData =  () => {
       if(showControlValveDecorator){
          ControlValveDecorator.enable();
       }else{
        ControlValveDecorator.disable();
       }
    };
    showControlvalveData();
  }, [showControlValveDecorator]);

  useEffect(() => {
    
    const showPipeSpoolData =  () => {
       if(showPipeSpoolsDecorator){
          PipeSpoolsDecorator.enable();
       }else{
        PipeSpoolsDecorator.disable();
       }
    };
    showPipeSpoolData();
  }, [showPipeSpoolsDecorator]);

  useEffect(() => {
    const showInstumentData =   () => {
       if(showInstrumentDecorator){
          InstrumentDecorator.enable();
       }else{
        InstrumentDecorator.disable();
       }
    };
    showInstumentData();
  }, [showInstrumentDecorator]);

  return (
    
    <InputGroup label=''>
      <ToggleSwitch label='Spool' defaultChecked={showPipeSpoolsDecorator} onChange={() => setShowPipeSpoolsDecorator((prevSpoolState) => !prevSpoolState)}/>
      <ToggleSwitch label='Equipment' defaultChecked={showEquipmentDecorator} onChange={() => setShowEquipmentDecorator((prevEquipState) => !prevEquipState)}/>
      <ToggleSwitch label='Instrument' defaultChecked={showInstrumentDecorator} onChange={() => setShowInstrumentDecorator((prevInstrumentState) => !prevInstrumentState)}/>
      <ToggleSwitch label='Control Valve' defaultChecked={showControlValveDecorator} onChange={() => setShowControlValveDecorator((prevCVState) => !prevCVState)}/>
    </InputGroup>
  );
};


export class ToggleWidgetProvider implements UiItemsProvider {
  public readonly id: string = "ToggleUiProvider";
  public static toggledOnce: boolean = false;
  public static originalColor: number;

  public provideWidgets(_stageId: string, _stageUsage: string, location: StagePanelLocation, _section?: StagePanelSection | undefined): ReadonlyArray<Widget> {
    const widgets: Widget[] = [];
    if (location === StagePanelLocation.Right &&
      _section === StagePanelSection.Start) {
      widgets.push(
        {
          id: "ControlWidget",
          label: "Labels",
          content: <Toggle />
        }
      );
    }
    return widgets;
  }

}