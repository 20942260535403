import { ColorDef, FeatureOverrideType } from "@itwin/core-common";
import { EmphasizeElements, IModelApp, IModelConnection, ViewChangeOptions} from "@itwin/core-frontend";
import { KeySet } from "@itwin/presentation-common";
import { Id64String } from "@itwin/core-bentley";
import { PipeData } from "../PipeRunList/PipeData";
import { CenteredSpool, PipeRunType, Spools } from "../types";
import { convertElementsToKeySet } from "../HelperFunctions";
import { PipeSpoolsDecorator } from "../PipeSpoolsDecorator";
import { ControlValveDecorator } from "./ControlValveDecorator";
import { InstrumentDecorator } from "./InstrumentDecorator";
import { PipeSpoolsMarker } from "../Markers/PipeSpoolsMarker";
import { SmartEquipmentData } from "../SmartEquipment/SmartEquipmentData";
import { EquipmentDecorator } from "./EquipmentDecorator";
import { ImageSource, ImageSourceFormat } from "@itwin/core-common";
import{ imageBitmapFromImageSource } from "@itwin/core-frontend";

export interface HiliteSet {
    models?: Id64String[];
    subCategories?: Id64String[];
    elements?: Id64String[];
}

export interface HiliteSetProviderProps {
    imodel: IModelConnection;
}

export declare class HiliteSetProvider {
    private _imodel;
    private _cached;
    private constructor();

    static create(props: HiliteSetProviderProps): HiliteSetProvider;
    private getRecords;
    private createHiliteSet;
    getHiliteSet(selection: Readonly<KeySet>): Promise<HiliteSet>;
}

export function populateLinks(pipe: PipeRunType) {
    let iSOLink = "https://www.bentley.com";
    let pIDLink = "https://www.bentley.com";
    let pipeRunObj = PipeData.pipesMap.get(pipe.pipeRun);
    if (pipe.iSOLink !== undefined) {
        iSOLink = pipe.iSOLink;
    }
    if (pipe.pIDLink !== undefined) {
        pIDLink = pipe.pIDLink;
    }
    const updatedObject = {
        ...pipeRunObj,
        iSOLink,
        pIDLink
    };

    PipeData.pipesMap.set(pipe.pipeRun, updatedObject);
}

export async function populateMap(pipes: PipeRunType[]) {
    pipes.forEach(async (pipe: PipeRunType) => {
        if (!PipeData.pipesMap.has(pipe.pipeRun)) {
            let hiliteElems: string[] = [];
            const elems = pipe.elements;
            let str = "";
            for (let i = 0; i < elems.length; i++) {
                str += elems[i];
            }
            const keySet = await convertElementsToKeySet(PipeData.iModel, JSON.parse(str));    
            const isoLink = pipe.iSOLink;
            const pidLink = pipe.pIDLink;
            const pipLine = pipe.pipeLine;
            PipeData.pipesMap.set(pipe.pipeRun, { keySet, hiliteElems, isoLink, pidLink, pipLine });
        }        
    });    
}

export async function populateAdjcentPipes(pipesMap: string, drawing : string | undefined) {
    // const matchingObjects = Array.from(PipeData.pipesMap.entries())
    // .filter(([key, value]) => value.pipLine === pipesMap);
    var lastDigit = 0;
    if(drawing !== undefined){
         lastDigit = parseInt(drawing.split('-')[2]) ?? 0;
    }
   
    const matchingObjects = Array.from(PipeData.pipesMap.entries())
    .filter(([key, value]) => value.pipLine === pipesMap)
    .sort(([aKey, aValue], [bKey, bValue]) => {
      const numericA = parseInt(aKey.split('-')[2]);
      const numericB = parseInt(bKey.split('-')[2]);
      return numericA - numericB;
    });
    
   
    
    return matchingObjects;
}


export async function zoomToElementsAndEmphasize(zoomElements: string[], lastEmphasizedPipes: string[], currentPipeLine : string, lastSelectedPipeLine: string | undefined) {
    const viewChangeOpts: ViewChangeOptions = {};
    viewChangeOpts.animateFrustumChange = true;
    const vp = IModelApp.viewManager.selectedView!;

    // Zoom   
    await vp.zoomToElements(zoomElements, { ...viewChangeOpts });

    // Emphasize
    const emphasize = EmphasizeElements.getOrCreate(vp);
    //alert(lastSelectedPipeLine);
    if (lastEmphasizedPipes.length !== 0 && currentPipeLine !== lastSelectedPipeLine) {
        emphasize.clearOverriddenElements(vp, lastEmphasizedPipes);
    }
    emphasize.emphasizeElements(zoomElements, vp);

    // Color the emphasized elements
    let defaultColor = ColorDef.fromString("#DD0004");
    emphasize.overrideElements(zoomElements, vp, defaultColor, FeatureOverrideType.ColorAndAlpha, false);
}

export async function onlyEmphasize(zoomElements: string[], lastEmphasizedPipes: string[]) {

    const vp = IModelApp.viewManager.selectedView!;
    // Emphasize
    const emphasize = EmphasizeElements.getOrCreate(vp);
    if (lastEmphasizedPipes.length !== 0) {
       emphasize.clearOverriddenElements(vp, lastEmphasizedPipes);
    }
    emphasize.emphasizeElements(zoomElements, vp);

    // Color the emphasized elements
    let defaultColor = ColorDef.fromString("#00ff00"); //#FBB117 previous color
    emphasize.overrideElements(zoomElements, vp, defaultColor, FeatureOverrideType.ColorAndAlpha, false);
}


export function clearEmphasisAndColor()
{
    const vp = IModelApp.viewManager.selectedView!;
    const emphasize = EmphasizeElements.getOrCreate(vp);
    emphasize.clearEmphasizedElements(vp);
    emphasize.clearOverriddenElements(vp);
}


// export function graphicFromRange3d(spoolData : any){
//     const decorator = new SpoolDecorator();
//     let distinctSpools = new Set();
//     let ranges3d: Range3d[] = [];
//     //let rangebox : Range3d = new Range3d();
//     //distinct sppoools
//     spoolData.forEach((item : any) => {
//         distinctSpools.add(item.spool);
//     });

//     distinctSpools.forEach((spools : any) => {
//             //getting 
//              const data = spoolData.filter((item : any) => item.spool === spools)

//              const ranges = createRange3D(data);
//              decorator.addranges(ranges);
//          }
//     );
    
//     //decorator.AddRanges(rangebox);
//     IModelApp.viewManager.addDecorator(decorator);
// }


// export function createRange3D(spooldata: any) : Range3d {
//    let totalrange : Range3d = new Range3d();
//    spooldata.forEach((items: any) => {
//      if(items.bBoxLow && items.bBoxHigh){
//         //let rd = Range3d.createXYZXYZ(items.bBoxLow.x,items.bBoxLow.y,items.bBoxLow.z,items.bBoxHigh.x,items.bBoxHigh.y,items.bBoxHigh.z);
//         let rd = Range3d.createXYZXYZ(items.minX,items.minY,items.minZ,items.maxX,items.maxY,items.maxZ);
//         totalrange.extendRange(rd);
//      }
//    });
   
//    return totalrange;
// }



// export function graphicMarkersfromData(data : Spools[]){
//     //const decorator = new SpoolBoxDecorator();
    
//      data.forEach((element : Spools) => {
//          const markers = new Marker(
//              { x: parseFloat(element.dryCGX.split(" ")[0])/39.370, y: parseFloat(element.dryCGY.split(" ")[0])/39.370, z: parseFloat(element.dryCGZ.split(" ")[0])/39.370},
//              { x: 50 , y: 50}
//          );

//          const htmlelment = document.createElement("div");
//          htmlelment.innerHTML =`<h5>${element.spoolName}</h5>`;

//          markers.htmlElement = htmlelment;
//          //decorator.addMarker(markers);
//      });
//      //IModelApp.viewManager.addDecorator(decorator);
// }

export function graphicFromBoxes(data : Spools[]){
    //const decorator = new SpoolBoxDecorator();
    // decorator.clearGeometry();
    // data.forEach((element : Spools) => {
    //     decorator.addBoxes(element);
    // });
    
    //IModelApp.viewManager.addDecorator(decorator);
}

export function removegraphicMarkersfromData() {  
    const vp = IModelApp.viewManager.selectedView!;
    const decorator = new PipeSpoolsDecorator(vp);
    const controlvalvedecortor = new ControlValveDecorator(vp);
    const insturmentvalvedecortor = new InstrumentDecorator(vp);
    const equipmentDecorator = new EquipmentDecorator(vp);

    IModelApp.viewManager.dropDecorator(controlvalvedecortor);
    IModelApp.viewManager.dropDecorator(insturmentvalvedecortor);
    IModelApp.viewManager.dropDecorator(decorator);
    IModelApp.viewManager.dropDecorator(equipmentDecorator);
    console.log(decorator);
   
}

export function enabledisableSpoolDecoration(enable : boolean){
    const vp = IModelApp.viewManager.selectedView!;
    const decorator = new PipeSpoolsDecorator(vp);
    
    if(enable){
        IModelApp.viewManager.addDecorator(decorator);
    }else{
        IModelApp.viewManager.dropDecorator(decorator);
    }
}

export const fetchImage = async (url: string): Promise<ImageBitmap> => {
    const buffer = await fetch(url).then((response) => response.arrayBuffer());
    const imageSource = new ImageSource(new Uint8Array(buffer), ImageSourceFormat.Png );
    return await imageBitmapFromImageSource(imageSource);
  }
  

export function calculateAverageOrigin(spools: Spools[]): {x: number, y: number, z: number} {
    let totalX = 0, totalY = 0, totalZ = 0;
    spools.forEach(spool => {
        totalX += spool.origin.x;
        totalY += spool.origin.y;
        totalZ += spool.origin.z;
    });
    return {
        x: totalX / spools.length,
        y: totalY / spools.length,
        z: totalZ / spools.length
    };
}


// Function to group spools by their values and display a marker for each group
export function displaySpoolGroupMarkers(spoolData: Spools[]) {
    const spoolGroups: Record<string, Spools[]> = {};
    spoolData.forEach(spool => {

        if (!spoolGroups[spool.spoolName]) {

            spoolGroups[spool.spoolName] = [];

        }

        spoolGroups[spool.spoolName].push(spool);

    });

 

    const centeredSpools: CenteredSpool[] = [];

    for (const spoolName in spoolGroups) {

        const averageOrigin = calculateAverageOrigin(spoolGroups[spoolName]);

        centeredSpools.push({

            spool: spoolName,

            origin: averageOrigin

        });

    }
    //graphicMarkersfromData(centeredSpools);
}


