import { StagePanelLocation,
  StagePanelSection, Widget , UiItemsProvider } from '@itwin/appui-react';
import WebcamScanner from './WebcamScanner';
import React from 'react';

export class ScannerUiProvider implements UiItemsProvider {
  public readonly id = 'ScannerUiProvider';
  public static toggledOnce: boolean = false;
  public static originalColor: number;
  public provideWidgets(
    stageId: string,
    stageUsage: string,
    location: StagePanelLocation,
    section?: StagePanelSection
  ): ReadonlyArray<Widget> {
    const widgets: Widget[] = [];

    if (
      location === StagePanelLocation.Right &&
      section === StagePanelSection.Start
    ) {
      const backgroundColorWidget: Widget = {
        id: 'BackgroundColorWidget1',
        label: 'Scan QR Code',
        content: <WebcamScanner />
      };

      widgets.push(backgroundColorWidget);
    }

    return widgets;
  }
}