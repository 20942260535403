import { ColorDef, RenderTexture } from "@itwin/core-common";
import { CreateTextureArgs, DecorateContext, Decorator, GraphicType, IModelApp, IModelConnection, ParticleCollectionBuilder, ParticleCollectionBuilderParams, ParticleProps, TextureImage, TextureOwnership, Viewport } from "@itwin/core-frontend";
import { Arc3d, AxisOrder, Cone, Matrix3d, Point3d, Ray3d, Sphere, Transform, Vector3d } from "@itwin/core-geometry";
import { dispose } from "@itwin/core-bentley";
import {fetchImage} from  "./Utils/Utils";

interface INorthArrowDecorator {
    new (name: RenderTexture, nameE : RenderTexture, nameEL : RenderTexture): NorthArrowDecorator;
}
/* This decorator draws a North Arrow at a fixed point on the view. */
class NorthArrowDecorator implements Decorator {
    
    private _arrowSizeInches = 0.50;
    private _paddingSizeInches = 1.2;
    private  _texture: RenderTexture | undefined;
    private  _textureE: RenderTexture | undefined;
    private  _textureEL: RenderTexture | undefined;
    public readonly useCachedDecorations = true;
    constructor(tx: RenderTexture, txE: RenderTexture, txEL: RenderTexture) {
        this._texture = tx;
        this._textureE = txE;
        this._textureEL = txEL;
    }

    // Generates new graphics and adds them to the scene
    public decorate(context: DecorateContext): void {
        const origin = this._getArrowOrigin(context.viewport);
        this._createGraphics(context, Ray3d.create (origin, Vector3d.unitY()));
        //this._drawNorthArrow(context, Ray3d.create (origin, Vector3d.unitY()));
        //this._addLetters(context, origin);
    }
    
    private _createGraphics(context: DecorateContext, northDir: Ray3d, id?: string): void {
        const vp = context.viewport;
        const pixelSize = vp.pixelsFromInches(this._arrowSizeInches);
        const scale = vp.viewingSpace.getPixelSizeAtPoint(northDir.origin) * pixelSize;
        const matrix = Matrix3d.createRigidFromColumns(northDir.direction, Vector3d.unitZ(), AxisOrder.YZX);

        if (undefined === matrix)
        return;

        matrix.scaleColumnsInPlace(scale, scale, scale);
        const arrowTrans = Transform.createRefs(northDir.origin, matrix);

        // Generate north arrow graphics and direction initial
        this._drawNorthArrow(context,arrowTrans,id);
        this._drawN(context,arrowTrans, scale, id);
        this._drawE(context,arrowTrans, scale, id);
        this._drawEL(context,arrowTrans, scale, id);
    }

    private _getArrowOrigin(vp: Viewport) {
        const rect = vp.viewRect;
        const pad = vp.pixelsFromInches(this._paddingSizeInches);
        const viewPoint = Point3d.create (rect.left + pad, rect.bottom - pad);
        const npcPoint = vp.viewToNpc(viewPoint);

        npcPoint.z =  0.5
        return vp.npcToWorld(npcPoint);
    }  

    private _drawNorthArrow(context: DecorateContext, northDir: Transform, id?: string): void {
        // const vp = context.viewport;
        // const pixelSize = vp.pixelsFromInches(this._arrowSizeInches);
        // const scale = vp.viewingSpace.getPixelSizeAtPoint(northDir.origin) * pixelSize;
        // const matrix = Matrix3d.createRigidFromColumns(northDir.direction, Vector3d.unitZ(), AxisOrder.YZX);
 
        // if (undefined === matrix)
        // return;
 
        // matrix.scaleColumnsInPlace(scale, scale, scale);
        // const arrowTrans = Transform.createRefs(northDir.origin, matrix);
 
        const northArrowBuilder = context.createGraphicBuilder(GraphicType.Scene, northDir, id);
        const color = ColorDef.black;
        
        // // const options = StrokeOptions.createForCurves();
        // options.needParams = false;
        // options.needNormals = true;
        // // const builder = PolyfaceBuilder.create(options);
        // // const cone = Simple3dApi.createCone(10,2.0,2.0);
        // // builder.addCone(cone);
 
        // const arrowOutline: Point3d[] = [];
        // arrowOutline[0] = Point3d.create(0.0, 0.65);
        // arrowOutline[1] = Point3d.create(-0.45, -0.5);
        // arrowOutline[2] = Point3d.create(0.0, -0.2);
        // arrowOutline[3] = Point3d.create(0.45, -0.5);
        // arrowOutline[4] = arrowOutline[0].clone();
 
        // const arrowLeftFill: Point3d[] = [];
        // arrowLeftFill[0] = arrowOutline[0].clone();
        // arrowLeftFill[1] = arrowOutline[1].clone();
        // arrowLeftFill[2] = arrowOutline[2].clone();
        // arrowLeftFill[3] = arrowLeftFill[0].clone();
 
        // const arrowRightFill: Point3d[] = [];
        // arrowRightFill[0] = arrowOutline[0].clone();
        // arrowRightFill[1] = arrowOutline[3].clone();
        // arrowRightFill[2] = arrowOutline[2].clone();
        // arrowRightFill[3] = arrowRightFill[0].clone();
 
        // northArrowBuilder.setSymbology(color, ColorDef.from(0, 0, 0, 200), 1);
        // northArrowBuilder.addArc(Arc3d.createXY(Point3d.createZero(), 0.6), true, true);
        // northArrowBuilder.addArc(Arc3d.createXY(Point3d.create(0.0, 0.85), 0.2), true, true);
 
        // northArrowBuilder.setSymbology(color, color, 2);
        // northArrowBuilder.addArc(Arc3d.createXY(Point3d.createZero(), 0.5), false, false);
        // northArrowBuilder.addLineString([Point3d.create(0.6, 0.0), Point3d.create(-0.6, 0.0)]);
        // northArrowBuilder.addLineString([Point3d.create(0.0, 0.6), Point3d.create(0.0, -0.6)]);
 
        // northArrowBuilder.setSymbology(color, ColorDef.from(150, 150, 150), 1);
        // northArrowBuilder.addShape(arrowLeftFill);
 
        // northArrowBuilder.setSymbology(color, ColorDef.black, 1);
        // northArrowBuilder.addShape(arrowRightFill);
 
        // northArrowBuilder.setSymbology(color, color, 1);
        // northArrowBuilder.addLineString(arrowOutline);
        // northArrowBuilder.setSymbology(color, color, 3);
        // northArrowBuilder.addLineString([Point3d.create(-0.1, 0.75), Point3d.create(-0.1, 0.95), Point3d.create(0.1, 0.75), Point3d.create(0.1, 0.95)]);
       
        // const coneEI = Cone.createAxisPoints(Point3d.create(0, 0, 7), Point3d.create(0, 0, 8.5), 0.5, 0, true);
        // const cylinderEI2 = Cone.createAxisPoints(Point3d.create(0, 0, 0), Point3d.create(0, 0, 7), 0.2, 0.2, true);
       
        // const sphereIE = Sphere.createCenterRadius(Point3d.create(0, 0, 4), 0.7);
       
        // const coneE = Cone.createAxisPoints(Point3d.create(4, 0, 4), Point3d.create(5.5, 0, 4), 0.5, 0, true);
        // const cylinderE2 = Cone.createAxisPoints(Point3d.create(-4, 0, 4), Point3d.create(4, 0, 4), 0.2, 0.2, true);
       
        // const coneN = Cone.createAxisPoints(Point3d.create(0, 4, 4), Point3d.create(0, 5.5, 4), 0.5, 0, true);
        // const cylinderN2 = Cone.createAxisPoints(Point3d.create(0, -4, 4), Point3d.create(0, 4, 4), 0.2,0.2, true);
 


        const coneEI = Cone.createAxisPoints(Point3d.create(0, 0,1), Point3d.create(0, 0, 1.5), 0.2, 0, true);
        const cylinderEI2 = Cone.createAxisPoints(Point3d.create(0, 0, -1), Point3d.create(0, 0, 1), 0.1, 0.1, true);
       
        const sphereIE = Sphere.createCenterRadius(Point3d.create(0, 0, 0), 0.25);
       
        const coneE = Cone.createAxisPoints(Point3d.create(1, 0, 0), Point3d.create(1.5, 0,0), 0.2, 0, true);
        const cylinderE2 = Cone.createAxisPoints(Point3d.create(-1, 0, 0), Point3d.create(1, 0, 0), 0.1, 0.1, true);
       
        const coneN = Cone.createAxisPoints(Point3d.create(0, 1, 0), Point3d.create(0, 1.5, 0), 0.2, 0, true);
        const cylinderN2 = Cone.createAxisPoints(Point3d.create(0, -1, 0), Point3d.create(0, 1, 0), 0.1, 0.1, true);
       

        northArrowBuilder.setSymbology(ColorDef.blue, ColorDef.blue, 1);
        northArrowBuilder.addSolidPrimitive(coneEI!);
        northArrowBuilder.addSolidPrimitive(cylinderEI2!);
        northArrowBuilder.setSymbology(ColorDef.from(255,255,0), ColorDef.from(255,255,0), 1);
        northArrowBuilder.addSolidPrimitive(sphereIE!);
        northArrowBuilder.setSymbology(ColorDef.red, ColorDef.red, 1);
        northArrowBuilder.addSolidPrimitive(coneE!);
        northArrowBuilder.addSolidPrimitive(cylinderE2!);
        northArrowBuilder.setSymbology(ColorDef.green, ColorDef.green, 1);
        northArrowBuilder.addSolidPrimitive(coneN!);
        northArrowBuilder.addSolidPrimitive(cylinderN2!);

        // const letterBuilder = context.createGraphicBuilder(GraphicType.WorldOverlay, arrowTrans,id);
        //  letterBuilder.setSymbology(color, color, 3);
//    //For E in 'EI'
//    letterBuilder.addLineString([Point3d.create(-0.2,0,1.65),Point3d.create(-0.2,0,2.10),
//     Point3d.create(-0.2,0,2.10), Point3d.create(0,0,2.10)]);
//     letterBuilder.addLineString([Point3d.create(-0.2,0,1.87),Point3d.create(0,0,1.87)]);
//     letterBuilder.addLineString([Point3d.create(-0.2,0,1.65),Point3d.create(0,0,1.65)]);
//     //For I in 'EI'
//     letterBuilder.addLineString([Point3d.create(0.1,0,1.60),Point3d.create(0.1,0,2.12)]);
//     //For E in 'E'
//     letterBuilder.addLineString([Point3d.create(1.6,0,-0.15),Point3d.create(1.6,0,0.35)]);
//     letterBuilder.addLineString([Point3d.create(1.6,0,0.35),Point3d.create(1.8,0,0.35)]);
//     letterBuilder.addLineString([Point3d.create(1.6,0,0.10),Point3d.create(1.8,0,0.10)]);
//     letterBuilder.addLineString([Point3d.create(1.6,0,-0.15),Point3d.create(1.8,0,-0.15)]);
//     //For N in 'N'
//     letterBuilder.addLineString([Point3d.create(-0.2,1.6,-0.15),Point3d.create(-0.2,1.6,0.35)]);
//     letterBuilder.addLineString([Point3d.create(-0.2,1.6,0.35),Point3d.create(0,1.6,-0.15)]);
//     letterBuilder.addLineString([Point3d.create(0,1.6,-0.15),Point3d.create(0,1.6,0.35)]);
  

       
        context.addDecorationFromBuilder(northArrowBuilder);
        //context.addDecorationFromBuilder(letterBuilder);
       
    }
    
     // This method makes use of Particle Effects from iTwin.js. It helps in positioning direction initial in such way that it is always facing to user. 
    // Sometime we call it as Billboarding. 
    private _drawN(context: DecorateContext, arrowTrans: Transform, scale:number, _id?: string){
        const org = Point3d.create(0,1.7,0); //0.9
        let locationOfN: ParticleProps=new Point3d();
        locationOfN = arrowTrans.multiplyPoint3d(org);

        if (this._texture === undefined ) return;

        // ParticleCollectionBuilder is used here to get the billboard effect.
        const params: ParticleCollectionBuilderParams = {
            texture: this._texture,  // the image to use
            size:scale * 0.5,                // scaling of image
            transparency: 60,       // transparancy of image
            viewport: context.viewport,
        };

        const builder = ParticleCollectionBuilder.create(params);
        builder.addParticle(locationOfN);
        const gfx = builder.finish();

        if (gfx) context.addDecoration(GraphicType.WorldOverlay, gfx);
    }


    private _drawE(context: DecorateContext, arrowTrans: Transform, scale:number, _id?: string){
        const org = Point3d.create(1.7,0,0); //0.9
        let locationOfE: ParticleProps=new Point3d();
        locationOfE = arrowTrans.multiplyPoint3d(org);

        if (this._textureE === undefined ) return;

        // ParticleCollectionBuilder is used here to get the billboard effect.
        const params: ParticleCollectionBuilderParams = {
            texture: this._textureE,  // the image to use
            size:scale * 0.4,                // scaling of image
            transparency: 60,       // transparancy of image
            viewport: context.viewport,
        };

        const builder = ParticleCollectionBuilder.create(params);
        builder.addParticle(locationOfE);
        const gfx = builder.finish();

        if (gfx) context.addDecoration(GraphicType.WorldOverlay, gfx);
    }

    private _drawEL(context: DecorateContext, arrowTrans: Transform, scale:number, _id?: string){
        const org = Point3d.create(0,0,1.7); //0.9
        let locationOfEL: ParticleProps=new Point3d();
        locationOfEL = arrowTrans.multiplyPoint3d(org);

        if (this._textureEL === undefined ) return;

        // ParticleCollectionBuilder is used here to get the billboard effect.
        const params: ParticleCollectionBuilderParams = {
            texture: this._textureEL,  // the image to use
            size:scale * 0.4,                // scaling of image
            transparency: 60,       // transparancy of image
            viewport: context.viewport,
        };

        const builder = ParticleCollectionBuilder.create(params);
        builder.addParticle(locationOfEL);
        const gfx = builder.finish();

        if (gfx) context.addDecoration(GraphicType.WorldOverlay, gfx);
    }
    
    
    public dispose() {
        this._texture = dispose(this._texture);
        this._textureE = dispose(this._textureE);
        this._textureEL = dispose(this._textureEL);
    }

}

// returns instance NorthArrowDecorator with texture  
const createNorthArrowDecorator = async (decorator :INorthArrowDecorator, iModelConnection:IModelConnection ): Promise<NorthArrowDecorator | undefined> =>{
    
    let decoratorInstance = undefined;
        try {
            // setup to create texture for particle
            const imgN = await fetchImage("/n.png");
            const textureImgN:TextureImage = {source:imgN}
            const textureOwnershipN:TextureOwnership = {iModel: iModelConnection, key:"hello"}
            const createTextureArgsN:CreateTextureArgs = {type:undefined, image:textureImgN, ownership:textureOwnershipN}
             
            const imgE = await fetchImage("/e.png");
            const textureImgE:TextureImage = {source:imgE}
            const textureOwnershipE:TextureOwnership = {iModel: iModelConnection, key:"hello2"}
            const createTextureArgsE:CreateTextureArgs = {type:undefined, image:textureImgE, ownership:textureOwnershipE}


            const imgEL = await fetchImage("/el.png");
            const textureImgEL:TextureImage = {source:imgEL}
            const textureOwnershipEL:TextureOwnership = {iModel: iModelConnection, key:"hello3"}
            const createTextureArgsEL:CreateTextureArgs = {type:undefined, image:textureImgEL, ownership:textureOwnershipEL}

            if (!imgN) 
                throw "Image was not created.";

            if (!imgE) 
                throw "Image was not created.";
            
            if (!imgEL) 
                throw "Image was not created.";


            const textureN = IModelApp.renderSystem.createTexture(createTextureArgsN);
            const textureE = IModelApp.renderSystem.createTexture(createTextureArgsE);
            const textureEL = IModelApp.renderSystem.createTexture(createTextureArgsEL);
            if (textureN && textureE && textureEL) 
                decoratorInstance = new decorator(textureN, textureE,textureEL);
        } catch (err) {
            console.error(err);
        }
        
    return decoratorInstance;
}

export { createNorthArrowDecorator, NorthArrowDecorator }
