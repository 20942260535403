import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Button } from '@itwin/itwinui-react';
import ScanOverlay from './ScanOverlay';
import { onlyEmphasize, populateAdjcentPipes, removegraphicMarkersfromData, zoomToElementsAndEmphasize } from '../Utils/Utils';
import { PipeData } from '../PipeRunList/PipeData';
import QrResult from "./QrResult";
import "./WebcamScanner.scss";
import { PipeRunType } from '../types';
import { HiliteElementsApi } from '../HiliteElementsApi';
import { SpoolData } from '../SpoolBox/SpoolData';
import { PipeSpoolsDecorator } from '../PipeSpoolsDecorator';
import { ControlValveDecorator } from '../Utils/ControlValveDecorator';
import { InstrumentDecorator } from '../Utils/InstrumentDecorator';
import { EquipmentDecorator } from '../Utils/EquipmentDecorator';

export default function WebcamScanner() {
    let initState: PipeRunType = {pipeRun: "", elements: [], iSOLink: "", pIDLink: "",pipeLine :""} 
    const [data, setData] = useState<PipeRunType>(initState);
    const [scan, setScan] = useState(false);
    const [correctScan, setCorrectScan] = useState(false);
    

    const handleScan = () => {
        setScan(true);
        setCorrectScan(false);
    }
    const handleStopScan = () => {
        setScan(false);
        setData(initState);
    }
    const handleRefresh = () => {
        handleStopScan();
        setCorrectScan(false);
        setTimeout(() => {
            handleScan()
        }, 100);
    }
    const handleResult = async (result: any, error: any) => {
       // console.log("QR result", result);
        if (!!result) {
            let dwgno = result?.text;
            if (dwgno !== undefined) {
                setData((data: PipeRunType) => ({ ...data, pipeRun: dwgno }));
                let zoomElementsArr = [];
                let pipesMap = PipeData.pipesMap;
                if (pipesMap.has(dwgno)) {
                    const value = pipesMap.get(dwgno);
                    const adjcentpipes = await populateAdjcentPipes(value.pipLine,dwgno);
                    const spooldata = await SpoolData.getSpoolData(dwgno);
                    console.log("value", JSON.stringify(value));
                    setData({ pipeRun: dwgno, elements: value.elements, iSOLink: value.isoLink, pIDLink: value.pidLink, pipeLine : value.pipeLine });
                    setCorrectScan(true);
                    
                    for await (const pipes of adjcentpipes) {
                    
                        if (pipes[1].hiliteElems.length === 0) {
                           const hiliteElementsApi = new HiliteElementsApi(PipeData.iModel);
                           const hiliteElemSet = await hiliteElementsApi.getHiliteSetElementIds(PipeData.iModel, pipes[1].keySet);
                      
                          // Update the object in the map
                          const originalObject = PipeData.pipesMap.get(pipes[0]);
                          if (originalObject) {
                            const updatedObject = {
                              ...originalObject,
                              hiliteElems: hiliteElemSet
                            };
                            PipeData.pipesMap.set(pipes[0], updatedObject);
                            zoomElementsArr = hiliteElemSet;
                          }else{
                            zoomElementsArr = pipesMap.get(dwgno).hiliteElems;;
                          }
                        }
                    }

                    const filterData = [];
                    for (const [key, value] of adjcentpipes) {
                        if (key !== dwgno) {
                          filterData.push(...pipesMap.get(key).hiliteElems);
                        }
                    }
    
                    // if (value.hiliteElems.length === 0) {
                    //     const hiliteElementsApi = new HiliteElementsApi(PipeData.iModel);
                    //     const arr = await hiliteElementsApi.getHiliteSetElementIds(PipeData.iModel, value.keySet);
                    //     //console.log(`hilite elements computed: ${arr}`);
                    //     //setZoomElementsArr(arr);
                    //     const originalObject = value;
                    //     const updatedObject = {
                    //         ...originalObject,
                    //         hiliteElems: arr
                    //     };
                    //     PipeData.pipesMap.set(dwgno, updatedObject);
                    //     zoomElementsArr = arr;
                    // } else {
                    //     zoomElementsArr = pipesMap.get(dwgno).hiliteElems;
                    // }

                    removegraphicMarkersfromData();
                    PipeSpoolsDecorator.displaySpoolGroupMarkers(spooldata);
                    ControlValveDecorator.getControlValve(dwgno);
                    InstrumentDecorator.getInstrument(dwgno);
                    EquipmentDecorator.getEquipmentList(dwgno);
                    await onlyEmphasize(filterData, PipeData.lastAdjacentEmphasizedPipes);
                    zoomToElementsAndEmphasize(pipesMap.get(dwgno).hiliteElems, PipeData.lastEmphasizedPipes,PipeData.pipesMap.get(dwgno).pipeLine,PipeData.lastSelectedPipeLine);
                    PipeData.lastEmphasizedPipes = PipeData.pipesMap.get(dwgno).hiliteElems;
                    PipeData.lastSelectedPipeLine = PipeData.pipesMap.get(dwgno).pipeLine;
                    PipeData.lastAdjacentEmphasizedPipes =  filterData;  
                    const customEvent = new CustomEvent("QRCodeScanned", { detail: dwgno });
                    document.dispatchEvent(customEvent);
                } else {
                    setCorrectScan(false);
                }
            }
        }
        if (!!error) {
           // console.info(error);
        }
    }
    return (

        <div className='container'>
            {scan && <QrReader
                scanDelay={300}
                onResult={handleResult}
                containerStyle={{ width: '100%' }}
                constraints={{ facingMode: 'environment' }}
                ViewFinder={ScanOverlay} />}
            <div className="button-container">
                <Button onClick={handleScan} disabled={scan} > Scan </Button>
                <Button onClick={handleStopScan} disabled={!scan}>Stop</Button>
                <Button onClick={handleRefresh} disabled={!scan} >Refresh</Button>
            </div>
            <QrResult scan={scan} correctScan={correctScan} data={data} />
        </div>
    );
};