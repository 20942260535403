import { QueryRowFormat } from "@itwin/core-common";
import { IModelConnection, ScreenViewport } from "@itwin/core-frontend";
import { Spools } from "../types";


export class SpoolData {
    public static iModel: IModelConnection;
    private _pipeRun : string | undefined;
    public static spools: Spools[] = [];
    //public static spoolsMap = new Map();
     /** Initialize the iModel based on viewport */
    public static initializeLink = async (vp: ScreenViewport) => {
        SpoolData.iModel = vp.iModel;
    };

    public static getSpoolData = async (piperun: string | undefined): Promise<any> => {
        //const query = `SELECT nwd.Spool,bis.ECInstanceId,bis.Origin,bis.Yaw,bis.Pitch,bis.Roll, bis.BBoxLow, bis.BBoxHigh FROM BIS.SpatialElement bis inner join (select Spool,ECInstanceId from NwdDynamic.Smart3D where DWGNO = '${piperun}') nwd on nwd.ECInstanceId = bis.ECInstanceId where bis.Origin is NOT Null AND bis.BBoxLow is Not Null and bis.BBoxHigh is not null`;
        //const query = `SELECT nwd.Spool,bis.ECInstanceId,nwd.DryCGX,nwd.DryCGY,nwd.DryCGZ,bis.Origin,bis.Yaw,bis.Pitch,bis.Roll, bis.BBoxLow, bis.BBoxHigh FROM BIS.SpatialElement bis inner join (select Spool,ECInstanceId,DryCGX,DryCGY,DryCGZ from NwdDynamic.Smart3D where DWGNO = '${piperun}') nwd on nwd.ECInstanceId = bis.ECInstanceId where bis.Origin is NOT Null AND bis.BBoxLow is Not Null and bis.BBoxHigh is not null and nwd.DryCGX is not null and nwd.DryCGY is not null and nwd.DryCGZ is not null`;
        //const query = `SELECT nwd.Spool,bis.ECInstanceId,bis.Origin,bis.Yaw,bis.Pitch,bis.Roll, bis.BBoxLow, bis.BBoxHigh FROM BIS.GeometricElement3d bis inner join (select Spool,ECInstanceId from NwdDynamic.Smart3D where DWGNO = '${piperun}') nwd on nwd.ECInstanceId = bis.ECInstanceId where bis.Origin is NOT Null AND bis.BBoxLow is Not Null and bis.BBoxHigh is not null`
        //const query = `SELECT smart3D.Spool, nwd.ECInstanceId FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO = '${piperun}'`;
        const query = `SELECT snwd.Spool as spoolName,ge3.ECInstanceId,ge3.Origin,ge3.Yaw,ge3.Pitch,ge3.Roll, ge3.BBoxLow, ge3.BBoxHigh FROM Bis.GeometricElement3d ge3 INNER join (SELECT smart3D.Spool, nwd.ECInstanceId FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO = '${piperun}') snwd on snwd.ECInstanceId = ge3.ECInstanceId where ge3.Origin is NOT Null AND ge3.BBoxLow is Not Null and ge3.BBoxHigh is not null and snwd.Spool is Not Null`;
        const results = SpoolData.iModel.query(query, undefined, { rowFormat: QueryRowFormat.UseJsPropertyNames });
        const values = [];
        for await (const row of results) {
            values.push(row);
        }
        return values;
    }

    // private async addSpoolData(){
    //     const values = await this.getSpoolData();
    // }

    
}

