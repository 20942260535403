import { QueryRowFormat } from "@itwin/core-common";
import { IModelConnection, ScreenViewport } from "@itwin/core-frontend";
import { PipeRunType } from "../types";

export class PipeData {
  public static iModel: IModelConnection;
  public static pipes: PipeRunType[] = [];
  public static pipesMap = new Map();
  public static lastEmphasizedPipes: string[] = [];
  public static lastAdjacentEmphasizedPipes: string[] = [];
  public static lastSelectedPipeLine : string | undefined;

  /** Initialize the iModel based on viewport */
  public static initializeLink = async (vp: ScreenViewport) => {
    PipeData.iModel = vp.iModel;
  };

  /** Fetch the Pipe Runs list based on ECSql query */
  public static fetchPipes = async (): Promise<any> => {
    let pipes: PipeRunType[] = PipeData.pipes;
    try {
      //const query = `SELECT smart3D.DWGNO AS PipeRun, json_group_array ( json_object ( 'id', printf('0x%x', nwd.ECInstanceId), 'class', ec_classname(nwd.ECClassId) ) ) AS Elements, smart3D.DLWebLink as ISOLink, smart3d.DLWebLink_PID as PIDLink FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO != 'NULL' GROUP BY smart3d.DWGNO ORDER BY smart3d.DWGNO`;
      //const query = `SELECT smart3D.DWGNO AS PipeRun , json_group_array ( json_object ( 'spools',smart3D.Spool,'id', printf('0x%x', nwd.ECInstanceId), 'class', ec_classname(nwd.ECClassId) ) ) AS Elements, '' as ISOLink, smart3d.DLWebLink_PID as PIDLink,smart3D.Pipeline as pipeLine  FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO != 'NULL' GROUP BY smart3d.DWGNO ORDER BY smart3d.DWGNO`;
      const query = `SELECT smart3D.DWGNO AS PipeRun , json_group_array ( json_object ( 'spools',smart3D.Spool,'id', printf('0x%x', nwd.ECInstanceId), 'class', ec_classname(nwd.ECClassId) ) ) AS Elements, smart3d.DLWebLink_PID as PIDLink,smart3D.DLWebLink as ISOLink,smart3D.Pipeline as pipeLine  FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO != 'NULL' GROUP BY smart3d.DWGNO ORDER BY smart3d.DWGNO`;
      const results = PipeData.iModel.query(query, undefined, { rowFormat: QueryRowFormat.UseJsPropertyNames });
      const values = [];
      for await (const row of results) {
        values.push(row);
      }
      pipes = values;      
    } catch (error) {
      console.log(error);
    }
    return pipes;
  };
}
