import {  QueryRowFormat } from "@itwin/core-common";
import { DecorateContext, Decorator, IModelApp, ScreenViewport } from "@itwin/core-frontend";
import { UiFramework } from "@itwin/appui-react";
import { PipeSpoolsMarker } from "../Markers/PipeSpoolsMarker";
//import { BBTool } from "./BBTool";

export class ControlValveDecorator implements Decorator {
   private static _markers: PipeSpoolsMarker[] = [];
   private static enabled: boolean = true;
   private static  vp : ScreenViewport;
    constructor(_vp: ScreenViewport) {
      ControlValveDecorator.vp = _vp;
    }

    public static enable(){
      ControlValveDecorator.enabled = true;
      this.vp.invalidateDecorations();
    }

    public static disable(){
      ControlValveDecorator.enabled = false;
      this.vp.invalidateDecorations();
    }
    
    public static SetMarkers(){
      ControlValveDecorator._markers = [];
      var data = JSON.parse(localStorage.getItem('ControlValve') || '{}');
      if(data.length > 0){
        data.forEach((element: any) => {
          const marker = new PipeSpoolsMarker(
              element.origin,
              { x: 20, y: 20 },
              element.name
          );
          ControlValveDecorator._markers.push(marker);
        });
      }
     
    }

    public static async getControlValve(list?: string) {
      if (!list) return;
      const values = [];
      const query = `SELECT snwd.Name,ge3.ECInstanceId,ge3.Origin FROM Bis.GeometricElement3d ge3 INNER join (SELECT nwd.ECInstanceId,smart3D.Name FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO = '${list}' and smart3d.ClassName_RN = 'CPRtePipeComponentOcc' AND Type not like '%Change' and Name not like '%Flange%' and Name not like '%olet%' and Name not like 'Nipple%' and Name not like 'Spectacle%' and Name not like 'Plug%' and Name not like '%Tee%' and Name not like 'Cap%' and Name not like 'olet%' and Name not like 'Tee%') snwd on snwd.ECInstanceId = ge3.ECInstanceId where ge3.Origin is NOT Null`;
      const result = UiFramework.getIModelConnection()!.createQueryReader(query, undefined, {
        rowFormat: QueryRowFormat.UseJsPropertyNames,
      });
      //const values = (await result.toArray()).map((element: any) => element);
      values.push(...(await result.toArray()));
      localStorage.setItem("ControlValve", JSON.stringify(values));
      ControlValveDecorator.SetMarkers();
    }

    public decorate(context: DecorateContext): void {
      if (!ControlValveDecorator.enabled) {
         return;
      }
      // Adding the markers as decorations
      ControlValveDecorator.SetMarkers();
      ControlValveDecorator._markers.forEach(marker => {
          marker.addDecoration(context);
      });
  }
}

