import React from 'react'
import './ScanOverlay.scss'
const ScanOverlay = () => {
    return (
        <svg viewBox='0 0 100 100' className="scanOverlay">
            <path fill="none" stroke="rgba(255,0,0,0.9)" strokeWidth="5" d="M13,0 L0,0 L0,13"></path>
            <path fill="none" stroke="rgba(255,0,0,0.9)" strokeWidth="5" d="M0,87 L0,100 L13,100 "></path>
            <path fill="none" stroke="rgba(255,0,0,0.9)" strokeWidth="5" d="M87,100 L100,100 L100,87"></path>
            <path fill="none" stroke="rgba(255,0,0,0.9)" strokeWidth="5" d="M100,13 L100,0 87,0"></path>
        </svg>
    )
}

export default ScanOverlay