import { PipeList } from './PipeList';
import React from 'react';
import { StagePanelLocation,
  StagePanelSection, Widget , UiItemsProvider } from '@itwin/appui-react';


export class PipeListUiProvider implements UiItemsProvider {
  public readonly id = 'PipeListUiProvider';
  public static toggledOnce: boolean = false;
  public static originalColor: number;

  public provideWidgets(
    stageId: string,
    stageUsage: string,
    location: StagePanelLocation,
    section?: StagePanelSection | undefined,
  ): ReadonlyArray<Widget> {
    const widgets: Widget[] = [];
    
    if (
      location === StagePanelLocation.Right &&
      section === StagePanelSection.Start
    ) {
      const backgroundColorWidget: Widget = {
        id: 'BackgroundColorWidget',
        label: 'Pipe List',
        content: <PipeList />
      };

      widgets.push(backgroundColorWidget);
    }

    return widgets;
  }
}