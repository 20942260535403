import { QueryRowFormat } from "@itwin/core-common";
import { IModelConnection, ScreenViewport } from "@itwin/core-frontend";
import { SmartEquipment } from "../types";


export class SmartEquipmentData {
    public static iModel: IModelConnection;
    public static equipments: SmartEquipment[] = [];
  
    /** Initialize the iModel based on viewport */
    public static initializeLink = async (vp: ScreenViewport) => {
        SmartEquipmentData.iModel = vp.iModel;
    };
  
    /** Fetch the Pipe Runs list based on ECSql query */
    public static fetchEquipment = async (): Promise<any> => {
      let equipments: SmartEquipment[] = SmartEquipmentData.equipments;
      try {
        //const query = `SELECT smart3D.DWGNO AS PipeRun, json_group_array ( json_object ( 'id', printf('0x%x', nwd.ECInstanceId), 'class', ec_classname(nwd.ECClassId) ) ) AS Elements, smart3D.DLWebLink as ISOLink, smart3d.DLWebLink_PID as PIDLink FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO != 'NULL' GROUP BY smart3d.DWGNO ORDER BY smart3d.DWGNO`;
        const query = `SELECT e.*,smartEquipment.Name,smartEquipment.Origin FROM bis.SpatialIndex e JOIN (select parent,Name,Origin,min(child) as child from(
            select parent_table.ECInstanceId parent, child_table.ECInstanceId child,smart3d.Name,child_table.Origin
             from NwdDynamic.Smart3D smart3d JOIN NwdDynamic.NwdComponent parent_table
              ON smart3d.Element.id = parent_table.ECInstanceId 
              JOIN NwdDynamic.NwdComponent child_table 
              ON parent_table.ECInstanceId = child_table.Parent.id 
              WHERE smart3d.ClassName_RN = 'CPSmartEquipment' 
              and child_table.Origin is not null) parent_child_pairs group by parent,Name)
               smartEquipment ON e.ECInstanceId = smartEquipment.child`;
        const results = SmartEquipmentData.iModel.query(query, undefined, { rowFormat: QueryRowFormat.UseJsPropertyNames });
        const values = [];
        for await (const row of results) {
          values.push(row);
        }
        equipments = values;      
      } catch (error) {
        console.log(error);
      }
      return equipments;
    };
  }