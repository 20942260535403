import { XAndY, XYAndZ } from "@itwin/core-geometry";
import { Marker } from "@itwin/core-frontend";

export class PipeSpoolsMarker extends Marker {
    private _spoolName: string;
    
    constructor(location: XYAndZ, size: XAndY, spoolName: string) {
        super(location, size);
        this._spoolName = spoolName;
        //console.log(`Creating marker for spool: ${this._spoolName} at location:`, location);
        const htmlElement = document.createElement("div");
        htmlElement.style.textAlign = "center";
        htmlElement.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
        htmlElement.style.color="black"; // Dark text for dark broswer
        htmlElement.innerHTML = `<span>${this._spoolName}</span>`;
        this.htmlElement = htmlElement;
    }
}
