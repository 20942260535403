import { ColorDef, Placement3d, QueryRowFormat } from "@itwin/core-common";
import { DecorateContext, Decorator, GraphicType, RenderGraphic, ScreenViewport } from "@itwin/core-frontend";
import { UiFramework } from "@itwin/appui-react";
import { Angle, Point3d, Range3d, YawPitchRollAngles } from "@itwin/core-geometry";
//import { BBTool } from "./BBTool";



export class SpoolBoxDecorator implements Decorator {
    private static boundingBoxes: any[];
    constructor(_vp: ScreenViewport) {
        SpoolBoxDecorator.boundingBoxes = [];
      //BBTool.register("BBTool");
    }
  
    public static async getBoundingBoxes(list?: string) {
      if (!list) return;
      const query = `SELECT ECInstanceId,Origin,Yaw,Pitch,Roll, BBoxLow, BBoxHigh FROM Bis.GeometricElement3d where ECInstanceId IN (${list}) AND Origin is NOT Null AND BBoxLow is Not Null and BBoxHigh is not null`;
      // const query = `SELECT snwd.Spool,ge3.ECInstanceId,ge3.Origin,ge3.Yaw,ge3.Pitch,ge3.Roll, ge3.BBoxLow, ge3.BBoxHigh FROM Bis.GeometricElement3d
      // ge3 INNER join (SELECT smart3D.Spool, nwd.ECInstanceId FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId WHERE smart3d.DWGNO = '${list}') snwd
      // on snwd.ECInstanceId = ge3.ECInstanceId where ge3.Origin is NOT Null AND ge3.BBoxLow is Not Null and ge3.BBoxHigh is not null`;
      const result = UiFramework.getIModelConnection()!.createQueryReader(query, undefined, {
        rowFormat: QueryRowFormat.UseJsPropertyNames,
      });
      SpoolBoxDecorator.boundingBoxes = (await result.toArray()).map((element: any) => element);
      console.log(SpoolBoxDecorator.boundingBoxes);
    }
  
    private _createGraphics(context: DecorateContext): RenderGraphic | undefined {
      const options = { wantNormals: true, type: GraphicType.WorldDecoration, generateEdges: true };
      const builder = context.createGraphic(options);

    
     // Create an object to store data grouped by "Spool"
     //const spoolData: { [spool: string]: SpoolsBoxData } = {};

      SpoolBoxDecorator.boundingBoxes.forEach((bb) => {
        // const spool = bb.spool;
       
        // if (!spoolData[spool]) {
        //   spoolData[spool] = {
        //     origins:  [],
        //     bBoxLows: [],
        //     bBoxHighs: [],
        //   };
        // }
     
        // spoolData[spool].origins.push(new Point3d(bb.origin.x, bb.origin.y, bb.origin.z));
        // spoolData[spool].bBoxLows.push(new Point3d(bb.bBoxLow.x, bb.bBoxLow.y, bb.bBoxLow.z));
        // spoolData[spool].bBoxHighs.push(new Point3d(bb.bBoxHigh.x, bb.bBoxHigh.y, bb.bBoxHigh.z));
  
        builder.setSymbology(ColorDef.black, ColorDef.black, 5);

        // let placement3d = new Placement3d(
        //   new Point3d(7519.067187427267, 1687.9791139983333, 200.6375732421875),
        //   new YawPitchRollAngles(
        //     Angle.createDegrees(bb.yaw),
        //     Angle.createDegrees(bb.pitch),
        //     Angle.createDegrees(bb.roll)
        //   ),
        //   new Range3d(-0.20319999754428864, -0.20319999754428864, -0.3158966064453125,0.20319999754428864, 0.20319999754428864, 0.3158966064453125)
        // );

        let placement3d = new Placement3d(
          new Point3d(bb.origin.x, bb.origin.y, bb.origin.z),
          new YawPitchRollAngles(
            Angle.createDegrees(bb.yaw),
            Angle.createDegrees(bb.pitch),
            Angle.createDegrees(bb.roll)
          ),
          new Range3d(bb.bBoxLow.x, bb.bBoxLow.y, bb.bBoxLow.z, bb.bBoxHigh.x, bb.bBoxHigh.y, bb.bBoxHigh.z)
        );

         placement3d && builder.addRangeBox(placement3d.calculateRange());
      });

         // Calculate and use average values for each "Spool"
        // for (const spool in spoolData) {
        //   if (spoolData.hasOwnProperty(spool)) {
        //     const spoolOrigins = spoolData[spool].origins;
        //     const spoolBBoxLows  = spoolData[spool].bBoxLows;
        //     const spoolBBoxHighs = spoolData[spool].bBoxHighs;

        //     // Calculate the average origin, bBoxLow, and bBoxHigh for this "Spool"
        //     const averageOrigin = this.calculateAveragePoint3d(spoolOrigins);
        //     //const averageBBoxLow = this.calculateSumPoint3d(spoolBBoxLows);
        //     //const averageBBoxHigh = this.calculateSumPoint3d(spoolBBoxHighs);
            
        //   // Create a range for bbox
        //     const bboxRange = new Range3d();
            
        //     // Extend the range for each "Spool" using the extendRange method
        //     spoolBBoxLows.forEach((low) => bboxRange.extend(low));
        //     spoolBBoxHighs.forEach((high) => bboxRange.extend(high));


        //     // Create placement3d and add range box using the average values
        //     const placement3d = new Placement3d(
        //       averageOrigin,
        //       new YawPitchRollAngles( Angle.createDegrees(0),
        //           Angle.createDegrees(0),
        //           Angle.createDegrees(0)), // You can adjust the angles as needed
        //       // new Range3d(averageBBoxLow.x, averageBBoxLow.y, averageBBoxLow.z,
        //       //   averageBBoxHigh.x, averageBBoxHigh.y, averageBBoxHigh.z)
        //       bboxRange
        //     );

        //     placement3d && builder.addRangeBox(placement3d.calculateRange());
        //   }
        // }
        const graphic = builder.finish();
        return graphic;
    }

    // Helper function to calculate the average of an array of Point3d objects
   private calculateAveragePoint3d(point3ds: Point3d[]): Point3d {
      if (point3ds.length === 0) {
        return new Point3d();
      }

      let sumX = 0;
      let sumY = 0;
      let sumZ = 0;

      point3ds.forEach((point) => {
        sumX += point.x;
        sumY += point.y;
        sumZ += point.z;
      });

      const averageX = sumX / point3ds.length;
      const averageY = sumY / point3ds.length;
      const averageZ = sumZ / point3ds.length;

      return new Point3d(averageX, averageY, averageZ);
   }

   private calculateSumPoint3d(point3ds: Point3d[]): Point3d {
    if (point3ds.length === 0) {
      return new Point3d();
    }

    let sumX = 0;
    let sumY = 0;
    let sumZ = 0;

    point3ds.forEach((point) => {
      sumX += point.x;
      sumY += point.y;
      sumZ += point.z;
    });

    return new Point3d(sumX, sumY, sumZ);
 }

    public decorate(context: DecorateContext) {
      let graphic = this._createGraphics(context);
      graphic && context.addDecoration(GraphicType.Scene, graphic);
    }
}

interface SpoolsBoxData {
  origins: Point3d[];
  bBoxLows: Point3d[];
  bBoxHighs: Point3d[];
}