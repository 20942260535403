import { DecorateContext, Decorator, ScreenViewport } from "@itwin/core-frontend";
import { PipeSpoolsMarker } from "./Markers/PipeSpoolsMarker";
import { CenteredSpool, Spools } from "./types";

export class PipeSpoolsDecorator implements Decorator {
    private static _markers: PipeSpoolsMarker[] = [];
    private static enabled: boolean = true;
    private static  vp : ScreenViewport;
    //private static boundingBoxes: any[];
    constructor(_vp: ScreenViewport) {
        PipeSpoolsDecorator.vp = _vp;
      //BBTool.register("BBTool");
    }


    public static enable(){
        PipeSpoolsDecorator.enabled = true;
        this.vp.invalidateDecorations();
      }
  
      public static disable(){
        PipeSpoolsDecorator.enabled = false;
        this.vp.invalidateDecorations();
      }
  
    public static displaySpoolGroupMarkers(data : Spools[]){
        const spoolGroups: Record<string, Spools[]> = {};
        data.forEach(spool => {
            if (!spoolGroups[spool.spoolName]) {
                spoolGroups[spool.spoolName] = [];
            }
            spoolGroups[spool.spoolName].push(spool);
        });
        PipeSpoolsDecorator._markers = [];
        const centeredSpools: CenteredSpool[] = [];
            for (const spoolName in spoolGroups) {
            const averageOrigin = PipeSpoolsDecorator.calculateAverageOrigin(spoolGroups[spoolName]);
                centeredSpools.push({
                    spool: spoolName,
                    origin: averageOrigin
                });
                centeredSpools.forEach(element => {
                            const marker = new PipeSpoolsMarker(
                                element.origin,
                                { x: 50, y: 50 },
                                element.spool
                            );
                            PipeSpoolsDecorator._markers.push(marker);
                });
               
            }
            //console.log(PipeSpoolsDecorator._markers);
    }

    private static calculateAverageOrigin(spools: Spools[]): {x: number, y: number, z: number} {

        let totalX = 0, totalY = 0, totalZ = 0;
    
        spools.forEach(spool => {
            totalX += spool.origin.x;
            totalY += spool.origin.y;
            totalZ += spool.origin.z;
        });
        return {
            x: totalX / spools.length,
            y: totalY / spools.length,
            z: totalZ / spools.length
        };
    
    }

    public decorate(context: DecorateContext): void {
        //console.log("Decorating with markers");
        if (!PipeSpoolsDecorator.enabled) {
            return;
        }
        var data = JSON.parse(localStorage.getItem('Spools') || '{}');
        if(data.length > 0){
           PipeSpoolsDecorator.displaySpoolGroupMarkers(data as Spools[]);
        }
        // Adding the markers as decorations
        PipeSpoolsDecorator._markers.forEach(marker => {
            marker.addDecoration(context);
        });
    }

}
