import {  QueryRowFormat } from "@itwin/core-common";
import { DecorateContext, Decorator, IModelApp, ScreenViewport } from "@itwin/core-frontend";
import { UiFramework } from "@itwin/appui-react";
import { PipeSpoolsMarker } from "../Markers/PipeSpoolsMarker";
import { SmartEquipmentData } from "../SmartEquipment/SmartEquipmentData";


//import { BBTool } from "./BBTool";

export interface InputValues {
    minx: number;
    miny: number;
    minz: number;
    maxx: number;
    maxy: number;
    maxz: number;
  }
  

export class EquipmentDecorator implements Decorator {
   private static _markers: PipeSpoolsMarker[] = [];
   
   private static enabled: boolean = true;
   private static  vp : ScreenViewport;
    constructor(_vp: ScreenViewport) {
      //EquipmentDecorator._markers = [];
       EquipmentDecorator.vp =_vp;
    }
    
    // public static async getMarkers(){
    //   return this._markers;
    // }
   
   
    public static enable(){
      EquipmentDecorator.enabled = true;
      this.vp.invalidateDecorations();
    }

    public static disable(){
      EquipmentDecorator.enabled = false;
      this.vp.invalidateDecorations();
    }
  
    public static SetMarkers(){
      EquipmentDecorator._markers = [];
      var data = JSON.parse(localStorage.getItem('Equipment') || '{}');
      if(data.length > 0){
        data.forEach((element: any) => {
          const marker = new PipeSpoolsMarker(
              element.origin,
              { x: 20, y: 20 },
              element.name
          );
          EquipmentDecorator._markers.push(marker);
        });
      }
   
    }

    public static async getEquipmentList(list?: string) {
      if (!list) return;
      const values: InputValues[] = [];
      let passtomarker = [];
      const query = `select min(si.MinX) minx ,min(si.MinY) miny, min(si.MinZ) minz, Max(si.MaxX) maxx,Max(si.MaxY) maxy,Max(si.MaxZ) maxz from bis.SpatialIndex si INNER join 
      (SELECT smart3D.Spool, nwd.ECInstanceId FROM NwdDynamic.Smart3D smart3D JOIN NwdDynamic.NwdComponent nwd ON smart3D.Element.id = nwd.ECInstanceId
       WHERE smart3d.DWGNO = '${list}') snwd on snwd.ECInstanceId = si.ECInstanceId`;
      const result = UiFramework.getIModelConnection()!.createQueryReader(query, undefined, {
        rowFormat: QueryRowFormat.UseJsPropertyNames,
      });
      //const values = (await result.toArray()).map((element: any) => element);
      values.push(...(await result.toArray()));
      //this._markers = [];
      passtomarker = EquipmentDecorator.findEquipments(values);
      localStorage.setItem("Equipment", JSON.stringify(passtomarker));
      EquipmentDecorator.SetMarkers();
    }
    
    public static findEquipments(values : InputValues[]) : any[] {
        
        const resultArray: any[] = [];
        SmartEquipmentData.equipments.forEach((coordinates) => {
            // Adding 5 meters to the input coordinates
            const adjustedMinX : Number = values[0].minx - 5;
            const adjustedMinY : Number = values[0].miny - 5;
            const adjustedMinZ: Number = values[0].minz - 5;
            const adjustedMaxX : Number = values[0].maxx + 5;
            const adjustedMaxY : Number = values[0].maxy + 5;
            const adjustedMaxZ : Number = values[0].maxz + 5;
            //adjustedMinZ <= coordinates.maxZ &&
            //&& adjustedMaxZ >= coordinates.minZ
            
            // Checking if the adjusted coordinates are within the specified range
            if (
              adjustedMinX <= coordinates.maxX &&
              adjustedMinY <= coordinates.maxY &&
              adjustedMinZ <= coordinates.maxZ &&
              adjustedMaxX >= coordinates.minX &&
              adjustedMaxY >= coordinates.minY 
              && adjustedMaxZ >= coordinates.minZ
            ) {
              // If within range, add to the result array
              resultArray.push(coordinates);
            }
          });
        
        return resultArray;
    }

    public decorate(context: DecorateContext): void {

      if (!EquipmentDecorator.enabled) {
        return;
      }

      // Adding the markers as decorations
      EquipmentDecorator.SetMarkers();
      EquipmentDecorator._markers.forEach(marker => {
          marker.addDecoration(context);
          
      });
      
  }
}

