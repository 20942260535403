import React from 'react'
import "./WebcamScanner.scss";
import { QrResultProps } from '../types';
import { Anchor } from '@itwin/itwinui-react';

const QrResult = (props: QrResultProps) => {
    let resultComponent;
    let { scan, correctScan, data } = props;
    if (scan) {
        if (correctScan) {
            resultComponent = <>
                <p> Scan Successful  </p>
                <span>Pipe : {data.pipeRun}
                    <div style={{ display: "flex" }}>                       
                        Links :  &nbsp;{data.iSOLink ? <Anchor style={{ float: "left" }} href={data.iSOLink} target="_blank">ISO</Anchor> : ""}
                        {data.pIDLink ? <Anchor style={{ float: "right", marginLeft: "5px" }} href={data.pIDLink} target="_blank">PID</Anchor>: ""}
                    </div>
                </span> </>
        }
        else {
            resultComponent = (data.pipeRun !== "") ? <><p> Scan Failed  </p><span>Pipe : {data.pipeRun} <p className="error">No matching pipe found</p></span> </> : <p>No result</p>
        }
    } else {
        resultComponent = <> <p>No result</p></>
    }
    return (<>{resultComponent}</>)
}

export default QrResult;